<template>
    <div class="tc" style="">
        <div class="pt4 pb3 f5 font-w1">{{ heading }}</div>

        <div class="flex justify-center items-center" v-if="orgData?.org">
            <img v-if="orgImage" :src="orgImage" width="90" alt="" />
            <div
                v-else
                class="flex justify-center items-center font-w3"
                style="
                    background: rgb(215, 222, 250);
                    height: 90px;
                    width: 90px;
                    border-radius: 50%;
                    font-size: 25px;
                    color: rgb(19, 44, 140);
                "
            >
                <span>{{ orgInitials || userInitials }}</span>
            </div>
        </div>

        <div style="color: #55596e">
            <div class="b pv3">{{ orgData?.org }}</div>
            <div class="pv1" v-if="orgData?.address">{{ orgData?.address }}</div>
            <div class="pv1" v-if="orgData?.phone">{{ orgData?.phone }}</div>
            <div class="pv1" v-if="orgData?.email">{{ orgData?.email }}</div>
        </div>
    </div>
</template>

<script>
import { useStore } from 'vuex'
import { computed } from 'vue'

export default {
    name: 'OrgData',
    props: { heading: { type: String } },

    setup() {
        const store = useStore()

        const orgData = computed(() => store?.state?.Auth?.userData)
        const orgInitials = computed(() => store?.state?.Auth?.orgInitials)
        const orgImage = computed(() => store?.state?.Auth?.orgImage)
        const userInitials = computed(() => store?.state?.Auth?.userInitials)

        return { orgData, orgInitials, orgImage, userInitials }
    }
}
</script>

<style scoped></style>
